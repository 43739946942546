<template>
  <div :class="classes">
    <div class="form__text">
      <h2 v-if="props.title">{{ props.title }}</h2>
      <div v-if="props.content" class="form__description" v-html="props.content"></div>
    </div>

    <form class="form__body">
      <div ref="fieldsWrapper" class="form__fields">
        <div v-for="field in props.fields" :class="getFieldClasses(field)">
          <template v-if="field.type === 'checkbox'">
            <label>
              <span>
                <input
                  type="checkbox"
                  :name="field.name"
                  :required="field.required || undefined"
                  @input="(e: any) => onFieldChange(field, e.target?.checked)"
                />
              </span>
              {{ field.label + (field.required ? ' *' : '') }}
            </label>
          </template>

          <template v-else-if="field.type === 'select'">
            <label>{{ field.label + (field.required ? ' *' : '') }}</label>
            <SelectInput
              :options="field.options || []"
              :placeholder="field.placeholder || ''"
              :nullable="false"
              :compact="props.layout === 'inline'"
              :outline="props.layout === 'inline'"
              @select="(option) => onFieldChange(field, option.value)"
            />
          </template>

          <template v-else-if="field.type === 'date'">
            <label>{{ field.label }}</label>
            <input
              type="date"
              :name="field.name"
              :min="getPragueDateString(new Date())"
              max="2035-12-31"
              :required="field.required || undefined"
              @input="(e: any) => onFieldChange(field, e.target?.value)"
            />
          </template>

          <template v-else>
            <label>{{ field.label + (field.required ? ' *' : '') }}</label>
            <input
              :type="field.type === 'phone' ? 'tel' : field.type"
              :placeholder="field.placeholder"
              :name="field.name"
              :min="field.type === 'number' ? field.min || 0 : undefined"
              :max="field.type === 'number' ? field.max || undefined : undefined"
              :required="field.required || undefined"
              @input="(e: any) => onFieldChange(field, e.target?.value)"
            />
          </template>
        </div>
      </div>

      <div class="form__actions">
        <button
          :class="`btn ${props.submitColor ? 'btn--' + props.submitColor.toLowerCase() : 'btn--red'}`"
          @click.prevent="submit"
        >
          {{ props.submitLabel || $t('forms.submit') }}
        </button>
        <p class="form__error-msg" v-if="hasErrors">{{ $t('forms.required_error') }}</p>
        <p class="form__error-msg" v-if="submitStatus === false">{{ $t('forms.submit_error') }}</p>
      </div>

      <transition>
        <div v-if="submitStatus" class="form__overlay">
          <svg class="form__success-check" width="20" height="20" viewBox="0 0 20 20">
            <path d="M 1.236 9.11 L 7.498 15.372 L 18.761 4.131" fill="none" stroke="currentColor" stroke-width="2" />
          </svg>
          <div v-html="props.successMessage || $t('forms.submit_success')" />
        </div>
      </transition>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { validateNumber, validateEmail, validatePhone, getPragueDateString } from '~/lib/helpers'
import type { SelectOption } from '~/lib/types'

interface FormField {
  name: string
  label: string
  type: 'text' | 'name' | 'email' | 'tel' | 'phone' | 'number' | 'select' | 'date' | 'checkbox'
  placeholder?: string
  options?: SelectOption[] // for type 'select'
  availableDates?: string[] // for type 'date'
  width?: 'half' | 'full'
  min?: number
  max?: number
  required?: boolean
}

const props = defineProps<{
  uuid: string
  title?: string | null
  content?: string | null
  submitLabel?: string | null
  submitColor?: string | null
  successMessage?: string | null
  layout?: string | null
  plain?: boolean
  fields: FormField[]
}>()

const app = useNuxtApp()

const fieldsWrapper = ref<HTMLElement | null>(null)

const changedFields = reactive<{ [key: string]: string | boolean}>({})
const invalidFields = ref(new Set<string>())
const hasErrors = ref(false)
const submitStatus = ref<boolean | null>(null)

const validateField = (field: FormField, value: string | boolean) => {
  const requiresValidation = ['email', 'tel', 'phone', 'number'].includes(field.type)

  let isValid = true
  if (field.type === 'number') isValid = validateNumber(value as string || '')
  if (field.type === 'email') isValid = validateEmail(value as string)
  if (field.type === 'tel' || field.type === 'phone') isValid = validatePhone(value as string)

  if ((field.required && !value) || (requiresValidation && !isValid)) {
    invalidFields.value.add(field.name)
  } else {
    invalidFields.value.delete(field.name)
    invalidFields.value.size === 0 && (hasErrors.value = false)
  }
}

const onFieldChange = (field: FormField, value: string | boolean) => {
  const _value = typeof value === 'string' ? value.trim() : value

  validateField(field, _value)
  changedFields[field.name] = _value
}

const submit = async () => {
  props.fields.forEach(field => validateField(field, changedFields[field.name]))
  hasErrors.value = !!invalidFields.value.size

  if (hasErrors.value) return

  const fields = props.fields.map(field => {
    const f ={
      key: field.name,
      value: `${ changedFields[field.name] || '' }`
    }
    if (field.type === 'select') Object.assign(f, {
      modelValue: `${ field.options?.find(o => o.value === changedFields[field.name])?.label }`
    })
    return f
  })

  const { data, error } = await useAsyncGql('SubmitForm', {
    uuid: props.uuid,
    fields
  })

  submitStatus.value = !(app._gqlLastResponse as any).errors?.length

  if (submitStatus.value) {
    fieldsWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

const getFieldClasses = (field: FormField) => [
  `form-field form-field--${field.type}`,
  field.width === 'half' ? 'form-field--lg-half' : props.layout === 'inline' ? '' : 'form-field--full',
  field.type === 'checkbox' ? 'form-field--shift' : '',
  invalidFields.value.has(field.name) ? 'form-field--error' : '',
  props.layout === 'inline' ? 'form-field--compact form-field--outline' : ''
]

const classes = computed(() => [
  'form',
  { 'form--plain': props.plain },
  { 'form--overlay-visible': submitStatus.value },
  {[`form--${props.layout}`]: !!props.layout},
])
</script>

<style lang="scss">
.form {
  display: flex;
  padding: _rem(64px);
  background-color: var(--color-dark-8-bg);
  border-radius: _rem(16px);

  &--inline {
    flex-direction: column;
    padding: _rem(32px) _rem(48px);
  }
  &--vertical {
    display: block;
  }
  &--plain {
    padding: 0;
    background-color: transparent;
  }

  .has-inverse-colors & {
    background-color: var(--color-light-16-bg);
  }

  @include breakpoint(medium down) {
    padding: _rem(48px) _rem(32px);
  }

  @include breakpoint(small down) {
    padding: _rem(32px);
    flex-direction: column;
  }

  &__text {
    flex: 1 0 50%;
    padding-right: _rem(32px);

    > * {
      max-width: _rem(400px);
    }
  }
    &--inline &__text h2 {
      font-size: _rem(32px);
    }

  &__description {
    margin-bottom: _rem(32px);
  }
  
  &__body {
    flex: 1 0 50%;
    position: relative;
  }
    &--inline &__body {
      @include breakpoint(medium up) {
        display: flex;
        align-items: flex-start;
        gap: _rem(16px);
      }
    }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    gap: _rem(16px);
    transition: all 500ms ease;
  }
    &--overlay-visible &__fields {
      opacity: 0.15;
      filter: blur(6px);
      pointer-events: none;
      user-select: none;
    }
    &--inline &__fields {
      flex: 1;
      margin-top: _rem(16px);
      .form-field {
        flex: 1;
        min-width: _rem(180px);
      }
      .form-field--checkbox {
        flex: 1 0 100%;
        width: 100%;
      }
    }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: _rem(16px);
    margin-top: _rem(32px);
  }
    &--inline &__actions {
      margin-top: _rem(44px);
    }

  &__error-msg {
    color: var(--color-red);
    font-weight: 500;
  }

  &__overlay {
    @include absolute-cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    font-weight: 500;
    font-size: _rem(18px);
    text-align: center;
    transition: opacity 100ms ease 200ms;

    &.v-enter-from {
      opacity: 0;
    }
  }

  &__success-check {
    color: var(--color-green);
    width: _rem(160px);
    height: auto;

    path {
      stroke-dasharray: 26;
      transition: all 500ms ease 200ms;

      .v-enter-from & {
        stroke-dashoffset: 26;
      }
    }
  }
}
</style>