<template>
  <div class="socials">
    <template v-if="props.shareUrl">
      <a v-for="it in shareNetworks" :href="it.link" target="_blank" class="btn btn--circle btn--small">
        <Icon :name="getIconName(it)" size="small" />
      </a>
    </template>
    <template v-else-if="props.items?.length">
      <a v-for="it in props.items" :href="it.link" target="_blank" class="btn btn--circle btn--small">
        <Icon :name="getIconName(it)" size="small" />
      </a>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Social } from '~/lib/types'

const props = defineProps<{
  items?: Social[]
  shareUrl?: string
}>()

const getIconName = (item) => {
  switch (item.type?.toLowerCase()) {
    case 'facebook': return 'facebook'
    case 'instagram': return 'instagram'
    case 'youtube': return 'youtube'
    case 'linkedin': return 'linkedin'
    case 'twitter': return 'twitter'
    case 'twitter/x': return 'twitter'
    case 'x': return 'twitter'
  }
  return ''
}

const shareNetworks = [
  { type: 'facebook', link: `https://www.facebook.com/sharer/sharer.php?u=${props.shareUrl}` },
  { type: 'linkedin', link: `https://www.linkedin.com/shareArticle?mini=true&url=${props.shareUrl}` },
  { type: 'twitter', link: `https://twitter.com/intent/tweet?url=${props.shareUrl}` }
]
</script>

<style lang="scss">
.socials {
  display: flex;
  align-items: center;
  gap: _rem(16px);
  flex-basis: fit-content;
  flex-grow: 0;

  .btn {
    background-color: var(--color-dark-blue);
    .has-inverse-colors & {
      background-color: var(--color-white);
    }
  }
}
</style>