import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetCareersList","GetCommons","GetLanguageStrings","GetPageable","GetPageableById","GetPageableTree","SubmitForm","BasicLocation","BasicPageables","Contacts","CtaItems","DynamicContentBlocks","EmbeddableBlocks","Extras","FooterBlocks","Form","HeroSettings","LinkItems","Media","MiniBlocks","ModelList","OpeningHours","PageableAncestors","PageableAncestorsModels","PageableWithParentsLeg","PressItem"]}
export const GqlGetCareersList = (...params) => useGql()('GetCareersList', ...params)
export const GqlGetCommons = (...params) => useGql()('GetCommons', ...params)
export const GqlGetLanguageStrings = (...params) => useGql()('GetLanguageStrings', ...params)
export const GqlGetPageable = (...params) => useGql()('GetPageable', ...params)
export const GqlGetPageableById = (...params) => useGql()('GetPageableById', ...params)
export const GqlGetPageableTree = (...params) => useGql()('GetPageableTree', ...params)
export const GqlSubmitForm = (...params) => useGql()('SubmitForm', ...params)