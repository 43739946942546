import { path, getModelImage } from './helpers'
import { h } from 'vue'

export const mapCtaObject = (obj) => ({
  text: obj?.cta,
  link: path(obj?.cta_link),
  color: obj?.cta_color,
  outline: obj?.cta_outline,
  external: obj?.cta_open_in_new || obj?.__typename === 'File' || obj?.model?.__typename === 'File'
})

export const mapSightseeingsToSlider = (block) => {
  const { $i18n } = useNuxtApp()

  const data = block.locations || block.models  

  const locations = data?.map(location => ({
    title: location?.title || '',
    image: getModelImage(location),
    description: location?.perex || '',
    link: path(location.pageable?.full_url || location.slug),
    ctaLabel: $i18n.t('location.discover_cta')
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides: locations,
    moreLink: block.cta_link,
    moreLabel: block.cta
  }
}

export const mapLocationsToSlider = (block) => {
  const data = block.locations || block.models

  const locations = data?.map(location => ({
    title: location?.title || '',
    image: getModelImage(location),
    // description: location?.perex || '',
    link: path(location.pageable?.full_url || location.slug),
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides: locations,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    display: 'cards',
    slideSize: 'wide'
  }
}

export const mapPagesToSlider = (block) => {
  const slides = block.models?.map(page => ({
    title: page?.title || '',
    image: page.hero_settings?.image?.[0],
    link: path(page.pageable?.full_url || page.slug),
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta
  }
}

export const mapFamilyMembersToSlider = (block) => {
  const members = block.models?.map(model => ({
    title: model.name || '',
    image: getModelImage(model),
    description: model.job_title || '',
    link: path(model.pageable?.full_url || model.slug),
    footerComponent: () => h('div', model.email)
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides: members,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    display: 'cards'
  }
}

export const mapTeamMembersToSlider = (block) => {
  const members = block.models?.map(model => ({
    title: model.name || '',
    image: getModelImage(model),
    description: model.job_title || '',
    footerComponent: () => h('div', model.email)
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides: members,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    display: 'cards'
  }
}

export const mapCareersToSlider = (block) => {
  const jobs = block.models?.map(model => ({
    title: model.title || '',
    description: model.job_title || '',
    tags: [model.city],
    link: path(model.pageable?.full_url || model.slug)
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides: jobs,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    display: 'jobs'
  }
}

export const mapRentGroupsToSlider = (block) => {
  const slides = block.models?.map(model => ({
    title: model.title || '',
    description: model.description || '',
    image: getModelImage(model),
    link: path(model.pageable?.full_url || model.slug)
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta
  }
}

export const mapTestimonialsToSlider = (block) => {
  const slides = block.models?.map(model => ({
    title: model.person,
    description: model.body,
    tags: model.tags,
    rating: model.rating,
    date: model.rated_at,
    image: model.image || model.hero
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    display: 'testimonials'
  }
}

export const mapCollectionToSlider = (block) => {
  const slides = block.models?.map(model => ({
    title: model.title,
    link: path(model.pageable?.full_url || model.slug),
    image: getModelImage(model)
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta,
    slideSize: 'tall'
  }
}

export const mapQrBlockToSlider = (block) => {
  const slides = block.items?.map(item => ({
    title: item.title,
    image: item.image,
    description: item.description
  })) || []

  return {
    title: block.title,
    slides,
    display: 'donations'
  }
}

export const mapComparisonBlockToSlider = (block) => {
  const slides = block.items?.map(item => ({
    title: item.title,
    subtitle: item.sub_title,
    tags: item.badge ? [{ text: item.badge, icon: item.badge_icon }] : [],
    content: item.content,
    ctas: item.ctas,
    highlightBadge: item.featured_badge
  })) || []

  return {
    title: block.title,
    slides: slides.filter(slide => slide.title),
    display: 'memberships'
  }
}

export const mapFormBlockToForm = (block) => {
  const fields = block.form?.fields
    ?.filter(field => field.__typename !== 'FormButton') // TODO: handle buttons
    .map(field => ({
      name: field.name,
      label: field.label,
      type: field.type || 'text',
      // placeholder: field.label,
      options: field.options?.map(opt => ({ value: opt.key, label: opt.value })) || [],
      // availableDates?: string[] // for type 'date',
      width: field.size || 'full',
      required: field.required || false
    })) || []

  const submitButton = block.form?.fields?.find(field => field.__typename === 'FormButton')

  return {
    uuid: block.form.uuid,
    title: block.title || block.form.title || '',
    content: block.description,
    submitLabel: submitButton?.label,
    submitColor: submitButton?.color,
    successMessage: block.form.thank_you_message,
    layout: block.form.layout,
    fields
  }
}

export const mapModelToTile = (model) => {
  return {
    link: path(model.link || model.pageable?.full_url || model.slug),
    // clickable?: boolean
    pretitle: model.pretitle || model.number || model.job_title || null,
    title: model.title || model.name || null,
    content: model.perex || model.description || model.content || model.text || null
  }
}

export const mapModelToSlide = (model) => {
  return model && {
    link: path(model.pageable?.full_url || model.slug),
    // clickable?: boolean
    image: getModelImage(model),
    description: model.description || model.perex || model.subtitle || null,
    title: model.title || model.name || null
  }
}

export const mapImagesToSlider = (block) => {
  const slides = block.images?.map(item => ({
    image: item.image,
    link: item.url
  })) || []

  return {
    title: block.title,
    slides,
    display: 'images'
  }
}

export const mapEventsToSlider = (block) => {
  const slides = block.models?.map(item => ({
    title: item.title,
    image: item.image,
    link: item.bokun_link // TODO
    //embedLink: item.bokun_link
  })) || []

  return {
    title: block.title,
    subtitle: block.description,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta,
  }
}

export const mapPressToSlider = (block) => {
  const slides = block.models?.map(item => ({
    title: item.title,
    image: item.image,
    link: item.file?.original_url
  })) || []

  return {
    title: block.title,
    slides,
    moreLink: block.cta_link,
    moreLabel: block.cta,
  }
}

export const mapRepeaterToSlider = (block) => {
  const slides = block.items?.map(item => ({
    title: item.title,
    link: item.link,
    description: item.description,
    image: item.image,
    clampDescription: !!item.image,
    textOnly: !item.image
  })) || []

  return {
    title: block.title,
    slides,
    display: 'cards'
  }
}

export const mapModelToGallery = (block) => {
  const items = block.models?.map(item => {
    const subtitleItems = [ item.collection?.title, (item.subtitle || item.sub_title) ].filter(item => item)

    return {
      title: item.title || item.name,
      subtitle: subtitleItems.join(' | '),
      description: item.description || item.perex,
      content: item.content,
      link: path(item.pageable?.full_url || item.slug || item.bokun_link), // TODO: workaround for bokun until iframe embedding is working ↓
      //link: path(item.pageable?.full_url || item.slug),
      // embedLink: item.bokun_link,
      image: getModelImage(item),
      type: item.collection?.title || item.type?.name || item.type,
      location: item.location?.title,
      modelName: item.__typename
    }
  }) || []

  return {
    items,
    modelName: block.model,
    moreLabel: block.cta,
    moreLink: block.cta_link,
    threshold: block.cta_treshold,
    background: block.background_color,
    anchor: block.anchor,
    title: block.title
  }
}

export const mapBannerBlock = (block) => {
  return {
    title: block.title,
    content: block.content,
    subContent: block.sub_content,
    cta: mapCtaObject(block),
    personName: block.person_name,
    personRole: block.person_role,
    personImage: block.person_image,
    personPhone: block.person_phone,
    personEmail: block.person_email
  }
}

export const mapOpeningHoursBlock = (block) => ({
  openingHours: block.opening_hours as any,
  locationName: block.location?.title || '&nbsp;',
  columns: block.columns || []
})

export const mapCalendarBlock = (block) => ({
  title: block.title,
  events: block.events || [],
  note: block.notice_text,
  noteColor: block.notice_color
})

export const mapContactToContactWidget = (contact) => ({
  pretitle: contact.contact_group,
  name: contact.contact || '',
  role: contact.contact_role,
  contactIcon: contact.contact_icon || 'email',
  contactValue: contact.contact_value || '',
  avatar: contact.contact_image,
})

export const mapLocationsToBlindMap = (locations) => {
  return locations.map(location => ({
    image: getModelImage(location),
    name: location.title,
    link: path(location.pageable?.full_url || location.slug),
    coords: [ location.lat, location.lng ]
  }))
}