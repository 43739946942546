<template>
  <div class="accordion">
    <h2 v-if="props.title">{{ props.title }}</h2>
    <template v-for="(item, i) in props.items">
      <div class="accordion__item">

        <div
          class="accordion__item-header"
          role="button"
          :aria-expanded="currentlyOpen === i"
          @click="() => toggleItem(i)"
        >
          <Icon :name="currentlyOpen === i ? 'minus' : 'plus'" class="icon--large" />
          <h3>{{ item }}</h3>
        </div>

        <Transition @enter="onOpen" @afterEnter="onAfterOpen" @leave="onClose" @afterLeave="onAfterClose">
          <div
            class="accordion__item-content-wrapper"
            :class="{ open: currentlyOpen === i }"
            v-show="currentlyOpen === i"
            role="region"
          >
            <div class="accordion__item-content">
              <slot :name="'content-' + i" />
            </div>
          </div>
        </Transition>

      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title?: string | null
  items: string[]
}>()

const currentlyOpen = ref<number | null>(null)

const toggleItem = (index: number) => currentlyOpen.value = currentlyOpen.value === index ? null : index

const onOpen = async (el) => {
  const height = el.clientHeight
  el.style.height = '0px'
  setTimeout(() => el.style.height = height + 'px', 10)
}
const onAfterOpen = (el) => el.style.removeProperty('height')
const onClose = (el) => el.style.height = el.clientHeight + 'px'
const onAfterClose = (el) => el.style.removeProperty('height')
</script>

<style lang="scss">
.accordion {
  h2 {
    margin-bottom: _rem(32px);
  }

  &__item {
    padding: _rem(16px);
    border-bottom: 1px solid var(--color-dark-16-lines);
    margin-bottom: 1px;

    &-header {
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;

      h3 {
        margin: 0 0 0 _rem(16px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      @include breakpoint(small down) {
        margin: 0 _rem(-16px);
        h3 {
          margin-left: _rem(8px);
        }
        .icon {
          width: _rem(24px);
          height: _rem(24px);
        }
      }
    }

    &-content {
      padding: _rem(32px) 0;
      @include breakpoint(small down) {
        padding: _rem(24px) 0;
      }
    }

    &-content-wrapper {
      left: _rem(64px);
      width: calc(100% - _rem(80px));
      position: absolute;
      transition: opacity 300ms linear 100ms, height 300ms ease;

      @include breakpoint(small down) {
        left: _rem(16px);
        width: calc(100% - _rem(16px));
      }

      &.v-enter-from { opacity: 0 }

      &.v-leave-active, &.open {
        width: 100%;
        position: static;
        padding-left: _rem(48px);
        @include breakpoint(small down) {
          padding-left: 0;
        }
      }

      &.v-leave-to {
        height: 0 !important;
        opacity: 0;
        transition: opacity 100ms ease, height 300ms ease;
      }
    }
  }
}
</style>