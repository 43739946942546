<template>
  <div ref="el" :class="classes" @blur="open = false">
    <div class="select__field" @click="open = !open" tabindex="0" role="combobox" :aria-expanded="open">
      {{ selected?.label || props.placeholder || '' }}
    </div>
    <div class="select__options" role="listbox">
      <div v-if="props.nullable"
        role="option"
        @click="() => onSelect({ value: null, label: props.placeholder || ''})"
      >
        {{ props.placeholder || '&nbsp;' }}
      </div>
      <div v-for="option in props.options"
        :class="{ active: option.value === selected?.value }"
        :title="option.label"
        role="option"
        @click="() => onSelect(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import type { SelectOption } from '~/lib/types'

const props = defineProps<{
  options: SelectOption[]
  default?: SelectOption
  placeholder?: string
  nullable?: boolean
  compact?: boolean
  outline?: boolean
}>()

const emit = defineEmits(['select'])

const el = ref<HTMLElement | null>(null)
const open = ref<boolean>(false)
const selected = ref<SelectOption | null>(props.default ?? null)

const onSelect = (option: SelectOption) => {
  selected.value = option
  emit('select', option)
  open.value = false
}

onClickOutside(el, () => open.value = false)

const classes = computed(() => [
  'select',
  !selected.value ? 'select--empty' : '',
  open.value ? 'select--open' : '',
  props.compact ? 'select--compact' : '',
  props.outline ? 'select--outline' : ''
])
</script>

<style lang="scss">
.select {
  color: var(--color-dark);
  text-align: left;
  user-select: none;
  position: relative;

  &__field {
    flex: 1;
    height: _rem(56px);
    padding: 0 _rem(16px);
    border: 1px solid var(--color-dark-16-lines);
    border-radius: _rem(8px);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    cursor: pointer;

    &:focus {
      border-color: var(--color-gold);
      outline: none;
    }

    &:after {
      content: '';
      display: block;
      width: _rem(9px);
      height: _rem(9px);
      border-right: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(45deg);
      position: absolute;
      right: _rem(18px);
      top: calc(50% - _rem(6px));
    }
  }
    &--open &__field {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:after {
        transform: rotate(-135deg);
        top: calc(50% - _rem(2px));
      }
    }
    &--empty &__field {
      color: var(--color-light-50);
    }
    &--compact &__field {
      height: _rem(40px);
    }
    &--outline &__field {
      background-color: transparent;
    }

  &__options {
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    position: absolute;
    background-color: var(--color-white);
    border: 1px solid var(--color-dark-16-lines);
    border-top: none;
    left: 0;
    right: 0;
    z-index: 1;
    display: none;

    > div {
      padding: _rem(16px) _rem(16px);
      user-select: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;

      &.active {
        font-weight: 500;
        color: #333;
      }

      &:hover {
        background-color: var(--color-dark-8-bg);
      }
    }
  }
    &--open &__options {
      display: block;
    }
}
</style>
