<template>
  <nuxt-link v-if="props.text" :to="props.link || ''"
    :external="isExternal"
    :target="isExternal ? '_blank' : undefined"
    :class="classes"
  >
    <span>{{ props.text }}</span>
    <Icon v-if="props.icon" :name="props.icon" />
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { CTAProps } from '~/lib/types'

const props = defineProps<CTAProps>()

const isExternal = computed(() =>
  props.external ||
  props.link?.startsWith('http')
)

const classes = computed(() => [
  'btn',
  props.color && props.color.toLowerCase() !== 'white' ? `btn--${props.color.toLowerCase()}` : '',
  props.circle ? 'btn--circle' : '',
  props.outline ? 'btn--outline' : ''
])
</script>

<style lang="scss">
// See /assets/scss/components/button.scss
</style>
