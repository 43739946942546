import type { MediaFragment, OpeningHoursFragment } from '#gql'
import type { Breadcrumb } from './types'

/**
 * @param weekday Day number; Monday = 1
 * @param locale Default 'cs-CZ'
 */
export const translateWeekday = (weekday: number, locale: string = 'cs-CZ'): string => {
  const dummyDate = new Date(2001, 0, weekday)
  const localized = dummyDate.toLocaleDateString(locale, { weekday: 'short' })
  // return localized[0].toUpperCase() + localized[1]
  return localized
}

/** Add zero before numbers lower than 10 */
export const zeroPad = (n: number) => (n < 10 ? '0' : '') + n

/** Get friendly string for date or date range: e.g. '10. 10. 2024' or '10.–12. 10. 2024' */
export const getDateString = (from: Date, to?: Date): string => {
  if (!from) return ''
  return to ? `${from.getDate()}.–${to.toLocaleDateString('cs-CZ')}` : from.toLocaleDateString('cs-CZ')
}

/** Get friendly time for date or date range: e.g. '10:30' or '10:30-13:00' */
export const getTimeString = (from: Date, to?: Date): string => {
  if (!from) return ''
  const toStr = (date) => date.toLocaleTimeString( 'cs-CZ', { hour: '2-digit', minute: '2-digit' } )
  return to ? `${toStr(from)}–${toStr(to)}` : toStr(from)
}

/** Get correct path without worrying for absolute or relative */
export const path = (path?: string | null, append?: string | null) => {
  if (path?.startsWith('http') || path?.startsWith('#')) return path

  const strippedPath = path?.replace(/(^\/)|(\/$)/, '') || ''
  const strippedAppend = append?.replace(/(^\/)|(\/$)/, '') || ''

  return '/' + strippedPath + (strippedAppend ? '/' + strippedAppend : '')
}

/** Sanitize anchor name so it can be used as id */
export const sanitizeAnchor = (anchor?: string | null) => {
  return anchor
    ?.replace(/[\s\/]/g, '-')
    .replace(/[#?]/g, '')
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    || ''
}

/** Removes HTML tags from text, paragraphs excluded by default */
export const clearTextFormatting = (text: string | null, exclude: string[] | null = ['p']) => {
  const regex = new RegExp(`<(?!\/?(${ exclude?.length ? exclude.join('|') : '%' })(?=>|\s?.*>))\/?.*?>`, 'gi')
  const replaced = text?.replace(regex, '')
  return replaced
}

/** Returns human readable string from file size in bytes */
export const humanizeFileSize = (bytes) => {
  if (!bytes && bytes !== 0) return 'NaN'

  let unit = 0
  let val = typeof bytes === 'string' ? parseInt(bytes) : bytes

  while (val >= 1000 || -val >= 1000) {
    val /= 1024
    unit++
  }

  return (unit ? val.toFixed(1) + ' ' : val) + ' KMGTPEZY'[unit] + 'B'
}

/** Returns Google Maps link for address */
export const getMapsUrl = (address?: string | null) => {
  if (!address) return ''
  const encodedAddress = encodeURIComponent(address).replace(/%20/g, '+')
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
}

export const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const validatePhone = (phoneNumber?: string) => {
  return ( (phoneNumber || '').match(/\d/g) || [] ).length > 8
}

export const validateNumber = (str: string) => {
  return !/\D/.test(str)
}

export const getModelImage = (model) => {
  if (!model) return null
  const media = (model.hero?.length && model.hero) || model.hero_settings?.image || model.image
  return media && (media.length ? media[0] : media)
}

/**
 * Parse OpeningHours from API
 * 
 * Returns groups of days with same opening hours & current open status
 */
export const parseOpeningHours = (data: OpeningHoursFragment[]) => {
  if (!data?.length) return { groups: [], isOpen: false }

  const { locale } = useI18n()

  const sorted = data.sort( (a, b) => a.dayInWeek?.num || 0 - (b.dayInWeek?.num || 0) )
  const groupedByDay = Object.entries( Object.groupBy(sorted, it => it.dayInWeek?.num || 0) )

  const now = new Date()
  const today = now.getDay()
  const time = `${ zeroPad(now.getHours()) }:${ zeroPad(now.getMinutes()) }`

  let isOpen = false

  // Format opening hours to friendly string: e.g. "10:00 - 18:00"
  const formatted = groupedByDay.map(([day, times]) => {
    const humanTimes = times?.map(it => {
      if (it.time_from && it.time_to && it.opened) {
        // While here, also check if current time falls into opening hours
        if (!isOpen) isOpen = parseInt(day) === today && time >= it.time_from && time <= it.time_to
        return `${it.time_from.substring(0, 5)}–${it.time_to.substring(0, 5)}`
      } else return null
    })
    return { day: parseInt(day), times: humanTimes?.join(', ') }
  })

  // Group consecutive days with same opening hours
  const grouped = formatted.reduce((groups: any[], { day, times }) => {
    const localizedDay = translateWeekday(day, locale.value)

    if (groups.length === 0) {
      groups.push({ days: [localizedDay], times })
      return groups
    }

    const lastGroup = groups[groups.length - 1]

    if (lastGroup.times === times) {
      lastGroup.days.length === 2
        ? lastGroup.days[1] = localizedDay
        : lastGroup.days.push(localizedDay)
    } else {
      groups.push({ days: [localizedDay], times })
    }

    return groups
  }, [])

  return { groups: grouped, isOpen }
}

/** Get breadcrumbs from pageable and its parents */
export const getBreadcrumbs = (pageable) => {
  if (!pageable) return []

  const breadcrumbs: Breadcrumb[] = []

  const addParentSlug = (parent) => {
    breadcrumbs.unshift({ title: parent.model?.title || parent.model?.name, path: '/' + parent.full_url })
    parent.parent && addParentSlug(parent.parent)
  }

  addParentSlug(pageable)

  return breadcrumbs
}

/** Get date string in YYYY-MM-DD converted to Prague timezone */
export const getPragueDateString = (date: Date) => {
  return new Intl.DateTimeFormat('sv-SE', { timeZone: 'Europe/Prague' }).format(date)
}

/**
 * Get specified size from responsive images of the Media type. Defaults to original_url if no responsive size found.
 */
export const getResponsiveImage = (mediaItem: MediaFragment | undefined, size: string) => {
  return mediaItem?.responsive_images?.find(it => it?.size === size)?.url || mediaItem?.original_url || ''
}

export const endsWithAny = (string: string, suffixes: string[]) => {
  return suffixes.some( suffix => string.endsWith(suffix) )
}
